import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField as TField,
} from "@material-ui/core";

import React, { forwardRef, useState, useRef, useEffect } from "react";
import Popup from "./Popup";
import { isDeepEquals } from "../../utils/isDeepEquals";
import { MdEdit } from "react-icons/md";
import NumberFormat from "react-number-format";

function DetailPanel(props) {
  const {
    rowData,
    parentAdditionalRows,
    showAddContact,
    prim_key,
    saveContact,
    updateRowDataList,
    tab,
  } = props;

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prev_props = usePrevious({
    rowData,
    parentAdditionalRows,
    showAddContact,
  });

  console.log(rowData, "prince111");
  const [additionalRows, setAdditionalRows] = useState([]);
  const [editedRows, setEditedRows] = useState([]);

  const [additionalRowId, setAdditionalRowId] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [firstAdd, setFirstAdd] = useState(false);
  const [noContactsFlag, setNoContactsFlag] = useState(true);

  const [popupData, setPopupData] = useState({
    title: "",
    children: <></>,
    acceptBtn: "",
    acceptBtnCallback: () => {},
    rejectBtnCallback: () => {},
    show: false,
  });
  const [_rowData, _setRowData] = useState(rowData);
  const [_rowData1, _setRowData1] = useState(rowData);

  const EMPTY_ROW_PANEL = {
    contact_name: "",
    phone_number: "",
    email: "",
    job_description: "",
    visit_count: 0,
    status: "-",
    isSaved: false,
    additionalRowKey: additionalRowId,
  };
  const statusPriorityMap = {
    Submitted: 1,
    "User Submitted": 2,
    "Quote Generated": 3,
    "Uploaded Document": 4,
    "UW Questions": 5,
    MQL: 6,
    SQL: 7,
    "Company Profile(Get Quote)": 8,
    "Company Profile": 9,
    "Landing Page": 10,
    "Visited Website": 11,
    "Affinity Logo Clicked": 12,
    "Fourth Logo Clicked": 13,
    "Clicked on Affinity logo": 14,
    "Clicked on Fourth logo": 15,
    "Clicked on the image": 16,
    "Clicked on insure comp logo": 17,
    "Watched Video": 18,
    "Visited LinkedIn": 19,
    "Visited Facebook": 20,
    "Visited Instagram": 21,
    "Accessed Social Media": 22,
    "Opened Email": 23,
    "Not Campaigned": 24,
    "-": 999,
  };

  useEffect(() => {
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));

    if (
      (parentAdditionalRows?.length && !_additionalRows?.length) ||
      (parentAdditionalRows?.length ===
        prev_props?.parentAdditionalRows?.length &&
        !isDeepEquals(parentAdditionalRows, prev_props?.parentAdditionalRows))
    ) {
      setAdditionalRows(parentAdditionalRows);
    }
  }, [parentAdditionalRows]);

  useEffect(() => {
    console.log("email_specific", rowData?.email_specific);
    if (rowData?.email_specific) {
    }
    if (rowData?.email_specific && Object.keys(rowData?.email_specific)) {
      console.log("objectKeys", Object.keys(rowData?.email_specific).length);

      setNoContactsFlag(Object.keys(rowData?.email_specific)?.length === 0);
    }
  }, [rowData]);

  const onAddContact = () => {
    if (!firstAdd) {
      setFirstAdd(true);
    }
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));
    _additionalRows.push(JSON.parse(JSON.stringify(EMPTY_ROW_PANEL)));
    setAdditionalRowId((prev) => prev + 1);
    setAdditionalRows(_additionalRows);
  };
  const onDeleteContact = (key) => {
    const newRows = additionalRows.filter(
      (item) => item.additionalRowKey !== key
    );
    setAdditionalRows(newRows);
  };
  const handleEditClick = (element, email) => {
    // _rowData.email_specific
    // console.log("element=>", element, _rowData);
    const tempRowData = JSON.parse(JSON.stringify(_rowData));
    const tempRowData2 = JSON.parse(JSON.stringify(_rowData));
    const tempVar = tempRowData?.email_specific?.[email]?.companyProfile;
    // console.log("iiiiii", Object.keys(tempVar), tempVar);

    for (let i of Object.keys(tempVar)) {
      // console.log("iiiiii", i, tempVar[i]);
      const tempObj = JSON.parse(JSON.stringify(tempVar[i]));
      tempVar[i] = tempObj.value;
    }

    if (!tempRowData2?.email_specific?.[email]?.isEdit) {
      tempRowData2.email_specific[email].isEdit = true;

      setFirstAdd(true);
      setEditedRows((prev) => [
        ...prev,
        { ...tempRowData?.email_specific?.[email]?.companyProfile },
      ]);
    }
    _setRowData(tempRowData2);
    // _rowData.email_specific;
  };

  const handleEditValueChange = (value, email, field) => {
    // console.log("handleEditValueChange", value, email, field);
    // setEditedRows((prev)=>{prev.find((item)=>item.emailId === email)[field]=value},...prev)
    _setRowData1((prevState) => {
      // Create a copy of the previous state
      const newState = { ...prevState };
      console.log("newState", newState);
      // Loop through all keys in the state object
      // for (const key in newState.email_specific) {
      // if (newState.email_specific[key].isEdit === true) {
      console.log("newState.email_specific", newState.email_specific, field);
      if (!newState.email_specific[email]["companyProfile"][field]?.value) {
        newState.email_specific[email]["companyProfile"][field] = {
          value: value,
        };
      } else {
        newState.email_specific[email]["companyProfile"][field].value = value;
      }
      // }
      // }

      return newState;
    });
    setEditedRows((prev) => {
      // Make a shallow copy of the array
      const newRows = [...prev];
      // console.log("prev=>", prev);
      // Find the index of the item to update
      const index = newRows.findIndex((item) => item?.emailId === email);
      // If the item is found, update the specific field
      // console.log("index=>", index);
      if (index !== -1) {
        newRows[index] = {
          ...newRows[index], // Make a shallow copy of the item
          [field]: value, // Update the specific field
        };
      }
      // console.log("newRows", newRows);
      return newRows;
    });
  };
  const checkAndUpdateAll = () => {
    _setRowData((prevState) => {
      // Create a copy of the previous state
      const newState = { ...prevState };

      // Loop through all keys in the state object
      for (const key in newState.email_specific) {
        if (newState.email_specific[key].isEdit === true) {
          newState.email_specific[key].isEdit = false;
        }
      }

      return newState;
    });
  };

  console.log("additional rows", editedRows); // Log previous state for debugging

  const onChangeValue = (key, val, indx, error) => {
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));
    _additionalRows[indx][key] = val;

    if (error) _additionalRows[indx][`${key}_error`] = true;
    else _additionalRows[indx][`${key}_error`] = false;

    setAdditionalRows(_additionalRows);
  };

  const validateEmail = (email) => {
    //check if email address is valid
    let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = reg.test(email);
    if (!isValid) return false;

    //check if email already exists in email_specific
    let existing_emails = _rowData.email_specific
      ? Object.keys(_rowData.email_specific)
      : [];
    if (existing_emails.includes(email)) return false;

    //check if email already exists in additional_rows
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));
    for (let _row of _additionalRows) if (_row.email === email) return false;

    return true;
  };

  const validateData = () => {
    console.log("parentData", _rowData, Object.keys(_rowData.email_specific));

    let hasIncompleteRow = additionalRows.some(
      (row) =>
        !(
          (
            row.contact_name &&
            // row.phone_number &&
            row.email
          )
          // row.job_description
        )
    );

    let invalidEmail = additionalRows.some((row) => row.email_error);
    let invalidPhone = additionalRows.some((row) => row.phone_number_error);

    if (hasIncompleteRow) {
      return { msg: "Please enter all fields", valid: false };
    }
    console.log("invalidEmail", invalidEmail);
    if (invalidEmail) {
      return { msg: "Please enter valid email id", valid: false };
    }
    if (invalidPhone) {
      return { msg: "Please enter valid phone number", valid: false };
    }

    return { msg: "", valid: true };
  };
  const updatePopUpData = (pData) => {
    setPopupData(pData);
  };

  return (
    <div style={{ padding: "25px" }}>
      <TableContainer component={Paper}>
        <Table size="small" className="sub_tab1" id="pre_campaigned_subtab">
          <TableHead
            style={{
              background: "#00000066",
              color: "whitesmoke",
            }}
          >
            <TableRow>
              <TableCell>Contact Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Job Description</TableCell>
              <TableCell>Visit Count</TableCell>
              <TableCell>Status</TableCell>
              {/* <TableCell>Campaign Name</TableCell> */}
              {showAddContact && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {_rowData.email_specific ? (
              Object.entries(_rowData.email_specific).map(
                ([email, element], index) => {
                  let step;
                  if (element.companyProfile) {
                    if (Number(element.proposalGeneratedCount) > 0) {
                      step = "Proposal Generated";
                    } else if (element.quote_status) {
                      if (element.quote_status === "uwportal") {
                        step = "UW Portal";
                      } else if (element.quote_status === "proceed_uw") {
                        step = "Approved";
                      } else if (element.quote_status === "reject") {
                        step = "Rejected";
                      }
                    } else if (Number(element.quoteGeneratedCount) > 0) {
                      step = "Quote Generated";
                    } else if (element.formStage) {
                      if (element.formStage === "two") {
                        if (element.nonEligibilityList) {
                          step = "Not Eligible";
                        } else {
                          step = "UW Questions";
                        }
                      } else if (element.formStage === "one") {
                        step = "Company Profile";
                      }
                    } else if (
                      element.visitCount &&
                      Number(element.visitCount) > 0
                    ) {
                      step = "Landing Page";
                    } else {
                      step = "-";
                    }
                    if (
                      element.companyProfile.status &&
                      element.companyProfile.status.value
                    ) {
                      step =
                        statusPriorityMap[element.companyProfile.status.value] <
                        statusPriorityMap[step]
                          ? element.companyProfile.status.value
                          : step;
                    }
                    return (
                      <TableRow key={index}>
                        <TableCell scope="row">
                          {element?.isEdit ? (
                            <TField
                              name="contact_name"
                              defaultValue={
                                element.companyProfile &&
                                element.companyProfile.contact_name
                                  ? element.companyProfile.contact_name.value
                                  : ""
                              }
                              // onChange={(e) =>
                              //   handleEditValueChange(e.target.value, email, "contact_name")
                              // }
                              onBlur={(e) =>
                                handleEditValueChange(
                                  e.target.value,
                                  email,
                                  "contact_name"
                                )
                              }
                            />
                          ) : (
                            <>
                              {element.companyProfile &&
                              element.companyProfile.contact_name
                                ? element.companyProfile.contact_name.value
                                : ""}
                            </>
                          )}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.isEdit ? (
                            <TField
                              name="phoneNumber"
                              defaultValue={
                                element?.companyProfile?.phoneNumber?.value?.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1) $2-$3"
                                ) ||
                                element?.companyProfile?.contact_number?.value?.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1) $2-$3"
                                ) ||
                                ""
                              }
                              // onChange={(e) =>
                              //   handleEditValueChange(e.target.value, email, "phoneNumber")
                              // }
                              onBlur={(e) =>
                                handleEditValueChange(
                                  e.target.value,
                                  email,
                                  "phoneNumber"
                                )
                              }
                            />
                          ) : (
                            <>
                              {element?.companyProfile?.phoneNumber?.value?.replace(
                                /(\d{3})(\d{3})(\d{4})/,
                                "($1) $2-$3"
                              ) ||
                                element?.companyProfile?.contact_number?.value?.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1) $2-$3"
                                ) ||
                                ""}
                            </>
                          )}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.emailId?.value}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.isEdit ? (
                            <TField
                              name="job_title"
                              defaultValue={
                                element?.companyProfile?.job_title?.value
                              }
                              // onChange={(e) =>
                              //   handleEditValueChange(e.target.value, email, "job_title")
                              // }
                              onBlur={(e) =>
                                handleEditValueChange(
                                  e.target.value,
                                  email,
                                  "job_title"
                                )
                              }
                            />
                          ) : (
                            <>{element?.companyProfile?.job_title?.value}</>
                          )}
                        </TableCell>
                        <TableCell scope="row">
                          {element.visitCount || 0}
                        </TableCell>
                        <TableCell scope="row">{step}</TableCell>
                        {/* <TableCell scope="row">
                            {lastCampaignName
                              ? lastCampaignName
                              : _rowData.latestCampaign}
                          </TableCell> */}
                        {/* <TableCell scope="row">-</TableCell> */}
                        {showAddContact && (
                          <TableCell>
                            <button
                              className="button-delete-row"
                              onClick={() => {
                                // onDeleteContact(ele?.additionalRowKey);

                                handleEditClick(element, email);
                              }}
                            >
                              <MdEdit />
                            </button>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  }
                }
              )
            ) : (
              <TableRow key={0}>
                <TableCell scope="row">{_rowData.contactName}</TableCell>
                <TableCell scope="row">
                  {_rowData.phoneNumber
                    ? _rowData.phoneNumber.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "($1) $2-$3"
                      )
                    : "-"}
                </TableCell>
                <TableCell scope="row">{_rowData.userEmail}</TableCell>
                <TableCell scope="row">{""}</TableCell>
                <TableCell scope="row">{_rowData.visitCount}</TableCell>
                <TableCell scope="row">{_rowData.step || ""}</TableCell>
                <TableCell scope="row">{_rowData.campaignMap}</TableCell>
                {/* <TableCell scope="row">-</TableCell> */}
              </TableRow>
            )}

            {additionalRows?.map((ele, indx) => {
              return (
                <TableRow key={ele.additionalRowKey}>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.contact_name
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue(
                            "contact_name",
                            e.target.value?.trim(),
                            indx
                          )
                        }
                        defaultValue={ele.contact_name}
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.phone_number
                    ) : (
                      <NumberFormat
                        defaultValue={ele.phone_number}
                        onChange={(e) => {
                          const value = e.target.value?.replace(/[^\d]/g, ""); // Remove non-digit characters
                          if (value && value.length === 10) {
                            onChangeValue(
                              "phone_number",
                              e.target.value?.trim(),
                              indx,
                              false
                            );
                          } else {
                            onChangeValue(
                              "phone_number",
                              e.target.value?.trim(),
                              indx,
                              true
                            );
                          }
                        }}
                        placeholder="(000) 000-0000"
                        autoComplete="off"
                        id="phoneNumber"
                        format="(###) ###-####"
                        type="text"
                        style={
                          ele.phone_number_error
                            ? {
                                border: "none",
                                borderBottom: "1px solid red",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                            : {
                                border: "none",
                                borderBottom: "1px solid #1890ff",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.email
                    ) : (
                      <input
                        type="text"
                        onChange={(e) => {
                          let val = e.target.value.trim();
                          if (val && validateEmail(val)) {
                            onChangeValue("email", val, indx, false);
                          } else {
                            onChangeValue("email", val, indx, true);
                          }
                        }}
                        defaultValue={ele.email}
                        style={
                          ele.email_error
                            ? {
                                border: "none",
                                borderBottom: "1px solid red",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                            : {
                                border: "none",
                                borderBottom: "1px solid #1890ff",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                        }
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.job_description
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue(
                            "job_description",
                            e.target.value.trim(),
                            indx
                          )
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                        defaultValue={ele.job_description}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {true ? (
                      ele.visit_count
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue(
                            "visit_count",
                            e.target.value.trim(),
                            indx
                          )
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                        defaultValue={ele.visit_count}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {true ? (
                      ele.status
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue("status", e.target.value.trim(), indx)
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                        defaultValue={ele.status}
                      ></input>
                    )}
                  </TableCell>
                  {/* <TableCell>-</TableCell> */}
                  <TableCell>
                    {ele.isSaved ? (
                      <>-</>
                    ) : (
                      <button
                        className="button-delete-row"
                        onClick={() => {
                          onDeleteContact(ele?.additionalRowKey);
                        }}
                      >
                        <img
                          style={{ height: "16px" }}
                          src={require("../../images/delete4.png")}
                        />
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            {showAddContact && (
              <TableRow key={1000}>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  {errMsg && <div style={{ color: "red" }}>{errMsg}</div>}

                  <button
                    className="btn mx-2 button-save-style"
                    onClick={(e) => onAddContact()}
                    disabled={noContactsFlag}
                  >
                    {/* <img
                        style={{ height: "16px" }}
                        src={require("../../images/plus4.png")}
                      /> */}
                    Add Contact
                  </button>

                  {firstAdd && (
                    <>
                      <button
                        className="btn button-save-style"
                        onClick={(e) => {
                          console.log("isEngaged", rowData?.isEngaged);

                          if (
                            validateData().valid ||
                            additionalRows.length === 0
                          ) {
                            // checkAndUpdateAll();
                            setErrMsg("");
                            updatePopUpData({
                              show: true,
                              title: firstAdd
                                ? "Contacts once added cannot be deleted. Would you like to continue?"
                                : "Would you like to continue?",
                              //"Would you like to continue"
                              acceptBtn: "Yes",
                              rejectBtn: "No",
                              acceptBtnCallback: () => {
                                _setRowData(_rowData1);
                                saveContact(
                                  prim_key,
                                  additionalRows,
                                  editedRows,
                                  tab
                                  //   (isEngaged = rowData?.isEngaged)
                                );
                                console.log("editedRows", editedRows.length);
                                if (editedRows?.length > 0) {
                                  updateRowDataList(prim_key, editedRows);
                                }
                              },
                              rejectBtnCallback: () => {
                                checkAndUpdateAll();
                              },
                            });
                          } else {
                            setErrMsg(validateData().msg);
                          }
                        }}
                      >
                        Save
                      </button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {popupData.show && (
        <Popup
          dashboardstyle={true}
          popupData={popupData}
          updatePopUpData={updatePopUpData}
        />
      )}
    </div>
  );
}
export default DetailPanel;
